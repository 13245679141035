import React, { useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { capitalize, lint } from './capitalize';
import { AppContainer, Header, IntroParagraph, ProductName, ByLine, ContentWrapper, ButtonSection, ButtonSectionTitle, } from './baseStyles';
import { EditableButton, OutputButton, } from './Button';
import { Linter } from './Linter';

// Add global styles
const GlobalStyle = createGlobalStyle`
 body {
   margin: 0;
   padding: 0;
   font-family: sans-serif;
   background-color: #fafafa;
   font-family: 'Inter', sans-serif;
 }

 html {
   font-size: 28px;
 }
`

function App() {
  const defaultText = "COMPARE";
  const [buttonText, setButtonText] = useState(defaultText);

  return (
    <React.Fragment>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap" rel="stylesheet" />

      <GlobalStyle/>
      <AppContainer>
        <Header>
          <ContentWrapper>
            <ProductName>Capitalizer</ProductName> 
            <ByLine>by Design @ Similarweb</ByLine>
          </ContentWrapper>
        </Header>

        <ContentWrapper>
          
          
          <IntroParagraph>
            Not sure how to capitalize a button?
            Enter your text below, and our automagical algorithm will give you a head start:
          </IntroParagraph>

          <ButtonSection>
            <ButtonSectionTitle>Before</ButtonSectionTitle>
            
            <EditableButton
              onInput={(e) => setButtonText(e)}
              placeholder={defaultText}
            />

            <div>
              <Linter value={lint(capitalize(buttonText))} />
            </div>
          </ButtonSection>

          <ButtonSection>
            <ButtonSectionTitle>After</ButtonSectionTitle>
            <OutputButton value={capitalize(buttonText) || "Nothing"} />
          </ButtonSection>
        </ContentWrapper>
      </AppContainer>
    </React.Fragment>
  );
}

export default App;
