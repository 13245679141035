import styled from 'styled-components';
import { BubbleStub } from './baseStyles';

const LinterBubble = styled('div')`
    ${BubbleStub({border: "red"})};
    max-width: 15rem;
    font-size: 0.5rem;
    text-align: left;
`

const LinterWarningList = styled('ul')`
    margin: 0;
    margin-left: 0.15rem;
    padding: 0.25rem 0.5rem 0 0.5rem;
`;

const LinterWarning = styled('li')`
    text-align: left;
`;

export const Linter = 
    ({value}) => {
        if (value.length === 0) {
            return <></>;
        } else {
            return <LinterBubble>
                <strong>{value.length === 1 ? "Warning" : "Warnings"}:</strong>
                <LinterWarningList>
                    {value.map(warning => (
                    <LinterWarning>
                        {warning}
                    </LinterWarning>
                    ))}
                </LinterWarningList>
            </LinterBubble>
        }
    };
