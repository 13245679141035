import styled, { css } from 'styled-components';

export const Header = styled('header')`
    border-bottom: 2px solid black;
    padding: 1rem 0;
    margin-bottom: 1rem;
`;

export const AppContainer = styled('div')`
    color: black;
    font-size: 1rem;
`;

export const ContentWrapper = styled('div')`
    max-width: 40rem;
    padding: 0 1rem;
    margin: 0 auto;
`

export const ProductName = styled('h1')`
    font-size: 1rem;
    font-weight: 700;
    padding: 0;
    margin: 0;
`;

export const ByLine = styled('div')`
    font-size: 0.5rem;
    font-weight: 400;
`;

export const IntroParagraph = styled('p')`
    margin: 1rem 0;
`;

export const ButtonSection = styled('div')`
    text-align: center;
    margin: 2rem 0;
`;

export const ButtonSectionTitle = styled('div')`
    font-size: 0.5rem;
    margin-bottom: 0.25rem;
`;

export const ButtonStub = css`
    border-radius: 1rem;
    font-size: 1rem;
    display: inline-block;
    min-width: 2rem;
    text-align: center;
    border: 2px solid black;
    padding: 0.25rem 0.5rem;
`

export const BubbleStub = ({border = "#000", bg= "#fff"}) => 
    css`
    border: 1px solid black;

    position: relative;
    display: inline-block;
    margin: 0 auto;
    margin-top: calc( 15px + 0.5rem );
    padding: 0.5rem;
    background: ${bg};
    border-radius: 4px;
    border: ${border} solid 2px;

    &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 15px 15px;
        border-color: ${bg} transparent;
        display: block;
        width: 0;
        z-index: 1;
        margin-left: -15px;
        top: -15px;
        left: 50%;
    }

    &:before {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 16px 16px;
        border-color: ${border} transparent;
        display: block;
        width: 0;
        z-index: 0;
        margin-left: -16px;
        top: -18px;
        left: 50%;
    }
`;