import styled from 'styled-components';
import React, { useRef, useEffect, useState } from 'react';
import { ButtonStub, BubbleStub } from './baseStyles';

export const EditableButton = styled(
    ({ className, onInput, placeholder="find keywords" }) => {
        const inputRef = useRef(null);
        const [firstRun, setFirstRun] = useState(true);

        useEffect(() => {
            if (firstRun) {
                inputRef.current.innerText = placeholder;
                setFirstRun(true);
            }
        }, [firstRun, placeholder]);

        const handlePaste = () => {
            requestAnimationFrame(
                () => {
                    inputRef.current.innerText = "" + inputRef.current.innerText;
                }, 0.1
            );
        }

        const handleChange = () => {
            onInput(inputRef.current.innerText);
        }

        return (
            <div 
                className={className}
                role="textbox"
                contentEditable={true}
                ref={inputRef}
                onPaste={handlePaste}
                onInput={handleChange}
            />
        )
    }
)`
    ${ButtonStub}

    &:focus {
        outline: none;
        border-color: darkslategray;
    }
`;

const CopyHint = styled('div')`
    opacity: ${(({visible}) => visible ? 1 : 0)};
    color: #fff;
    font-size: 0.5rem;
    user-select: none;

    ${BubbleStub({bg: "#000"})};
`;

export const OutputButton = styled(({className, value}) => {
    const [hovering, setHovering] = useState(false);
    const [copied, setCopied] = useState(false);

    const handleClick = () => {
        navigator.clipboard.writeText(value).then(() => {
            setCopied(true);
        })

    }

    return (<React.Fragment>
        <div 
            className={className}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => { setHovering(false); setCopied(false) }}
            onClick={handleClick}
        >
            {value}
        </div>
        <div>
            <CopyHint visible={hovering}>
                {
                    copied ? "Copied!" : "Click to copy"
                }
            </CopyHint>
        </div>
    </React.Fragment>);
})`
    cursor: pointer;
    user-select: none;
    ${ButtonStub}

    &:active {
        background: #000;
        color: #fff;
    }
`